<ng-container *ngIf="vendorSetup">
  <ng-container *ngIf="(mainSettings$ | async) as settings">
    <div class="w-100 flex-row border-bottom"
         style="display: flex; height: 60px; background-color: var(--ion-color-light); z-index: 10001;">
      <ng-container *ngIf="(settings.mobile_app_layout === 'improved' || settings.mobile_app_layout === 'feature-image')">
        <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
          <div class="tab-container w-100 h-100" *ngIf="hideLinks('/rewards')"
               routerLinkActive="active" [routerLink]="rewardsRoute" id="rewardsTab">
            <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px">
              <i class="bi bi-award"></i>
              <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Rewards</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(settings.mobile_app_layout !== 'improved' || settings.mobile_app_layout !== 'feature-image')">
        <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
          <div class="w-100 h-100 tab-container" [routerLink]="profileRoute"
               routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" id="dashboardTab">
            <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px" >
              <i class="bi bi-grid"></i>
              <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Dashboard</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="w-100 h-100 tab-container" [routerLink]="editProfileRoute"
           routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" id="profileTab">
        <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px">
          <i class="bi bi-person"></i>
          <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Profile</div>
        </div>
      </div>
      <ng-container *ngIf="vendorSetup.order_history_provider !== 'none'">
        <div class="w-100 h-100 tab-container"
             [routerLink]="previousOrdersRoute" id="ordersTab" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px">
            <i class="bi bi-receipt-cutoff"></i>
            <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Orders</div>
          </div>
        </div>
        <ng-container *ngIf="settings.mobile_app_layout === 'classic'">
          <div class="tab-container w-100 h-100"
               [routerLink]="savedCardsRoute" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px">
              <i class="bi bi-credit-card"></i>
              <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Payments</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="settings.mobile_app_layout === 'classic'">
        <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
          <div class="tab-container w-100 h-100" *ngIf="hideLinks('/rewards')"
               routerLinkActive="active" [routerLink]="rewardsRoute" id="rewardsTab">
            <div style="display: grid; place-items: center; padding: 10px; grid-template-columns: auto auto; grid-gap: 8px">
              <i class="bi bi-award"></i>
              <div class="ensurePointerCursor fw-bold" style="font-size: 16px">Rewards</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
