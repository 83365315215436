<div
        [class.location-card-wrapper]="displayInfoButton"
        [class.individual-location-wrapper]="!displayInfoButton"
        [class.location-card-shadow]="location?.isLive && displayInfoButton"
        [class.currentLocationBorder]="isCurrentLocation && !displayInfoButton"
        (click)="orderNowButtonClicked($event)"
        (mouseenter)="cardHovered()"
        (mouseleave)="listCardLeft()">
    <ng-container *ngIf="!isLoading; else skeleton">
        <div class="d-flex flex-row flex-nowrap">
            <!-- location information (left) -->
            <div class="d-flex flex-row flex-wrap grow-1 w-100">
                <!-- location address -->
                <div class="d-flex flex-row flex-nowrap grow-1 pb-2 w-100">
                    <div class="d-flex flex-row flex-wrap w-100 fw-bold">
                        <ng-container *ngIf="!cardEmphasis">
                            <!-- location name -->
                            <div class="d-flex align-items-center w-100 font-size-body-large">
                                <a
                                        *ngIf="location?.externalLink"
                                        [href]="location?.externalLink"
                                class="external-link text-underline">
                                    <h5 id="locTitle" class="header-font fw-bold mb-0">
                                        {{ location?.name }}
                                    </h5>
                                </a>
                                <h5 *ngIf="!location?.externalLink" id="locTitle" class="header-font fw-bold mb-0">
                                    {{ location?.name }}
                                </h5>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="cardEmphasis">
                            <!-- address line 1 -->
                            <h5 class="w-100 header-font fw-bold mb-1">
                              {{location.address | streetAddress}}
                            </h5>
                            <!-- address line 2 -->
                            <h5 class="w-100 header-font fw-bold mb-0">
                                {{ location?.address.city }}
                                , {{ location?.address.state }} {{ location?.address.zipCode }}
                            </h5>
                        </ng-container>
                        <div
                                class="d-flex flex-row flex-nowrap align-items-center w-100 mt-2">
                            <!-- open -->
                            <ng-container *ngIf="location?.isOpen">
                                <div class="open-badge">Open</div>
                            </ng-container>
                            <!-- closed but allows advanced orders -->
                            <ng-container
                                    *ngIf="!location?.isOpen && location?.supportsAdvancedOrders">
                                <div class="preorder-badge">Preorder</div>
                            </ng-container>
                            <!--                    closed and does not allow advanced orders-->
                            <ng-container
                                    *ngIf="!location?.isOpen && !location?.supportsAdvancedOrders">
                                <div class="closed-badge">Closed</div>
                            </ng-container>
                            <!-- current location -->
                            <div *ngIf="isCurrentLocation" class="your-current-badge text-bg-dark ms-1">
                                Current Location
                            </div>
                        </div>
                    </div>
                    <!-- distance -->
                    <div class="ps-2 d-flex flex-column align-items-end">
                        <ng-container *ngIf="!(distance | isNan)">
                            <div
                                    class="alert alert-info p-1 m-0 font-size-body-small text-nowrap text-end w-fit-content ms-auto mb-3"
                                    id="distanceText">
                                {{ distance }} mi
                            </div>
                        </ng-container>

                        <ng-container *ngIf="location.conceptLogoURL">
                            <img
                                    class="concept-logo"
                                    [src]="location.conceptLogoURL"
                                    [alt]="location.name"/>
                        </ng-container>
                    </div>
                </div>
                <!-- location statuses -->

                <div class="d-flex flex-column w-100 pb-2">
                    <ng-container *ngIf="!cardEmphasis">
                        <!-- address line 1 -->
                        <div class="w-100 font-size-body-small">
                          {{location.address | streetAddress}}
                        </div>
                        <!-- address line 2 -->
                        <div class="w-100 font-size-body-small">
                            {{ location?.address.city }}, {{ location?.address.state }}
                            {{ location?.address.zipCode }}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="cardEmphasis">
                        <!-- location name -->
                        <div class="w-100 font-size-body-small">
                            <a *ngIf="location?.externalLink" [href]="location?.externalLink">
                                <div id="locTitle" class="text-underline">
                                    {{ location?.name }}
                                </div>
                            </a>
                            <div *ngIf="!location?.externalLink" id="locTitle">
                                {{ location?.name }}
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- location info -->
                <div class="w-100 pb-2">
                    <!-- location phone number -->
                    <div class="w-100 font-size-body-small" *ngIf="location?.phoneNumber">
                        {{ location?.phoneNumber }}
                    </div>
                  <!-- location hours -->
                  <ng-container *ngIf="location">
                    <ng-container *ngIf="locationList; else fullHours">
                      <div class="w-100 font-size-body-small" *ngIf="location?.pickupHours && pickupStart && pickupEnd">
                        {{ pickupStart | date: 'shortTime' : location?.utcOffset }} -
                        {{ pickupEnd | date: 'shortTime' : location?.utcOffset }}
                      </div>
                    </ng-container>
                    <ng-template #fullHours>
                      <ng-container *ngFor="let hours of location.pickupHours | businessHours">
                        <div class="w-100 font-size-body-small">
                          {{ hours }}
                        </div>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                </div>

                <!-- order methods -->
              <div class="d-flex flex-row flex-nowrap justify-content-between align-items-end w-100  pb-2">
                <div class="font-size-body-small">
                  <span class="fw-bold ">{{ handoffMethods }}</span>
                  <ng-container *ngIf="unavailable">
                    <br>
                    <span class="font-size-xsmall">*{{unavailable}}</span>
                  </ng-container>
                </div>
              </div>

                <ng-container *ngIf="!location.isLive">
                    <div
                            class="d-flex flex-row flex-wrap font-size-body-small pb-2 w-100">
                        <div class="text-danger d-flex flex-row flex-nowrap me-3">
                            <i class="bi bi-exclamation-triangle"></i>
                            <div class="ps-1">Online Ordering Temporarily Unavailable</div>
                        </div>
                        <ng-container
                                *ngIf="
                location.isLive &&
                !location?.supportsPickup &&
                !location?.supportsCurbside
              ">
                            <div class="text-danger d-flex flex-row flex-nowrap me-3">
                                <i class="bi bi-exclamation-triangle"></i>
                                <div class="ps-1">Pickup not available from this location</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!locationList">
                            <a type="button"
                               tabindex="0"
                               class="de-primary-link p-0 text-capitalize text-nowrap pointer me-3"
                               (click)="openNavigation($event)"
                               (keydown.enter)="openNavigation($event)">
                                Get Directions
                            </a>
                        </ng-container>
                    </div>
                </ng-container>

                <!-- order now and location info buttons/links (right) -->
                <div class="d-flex flex-row flex-nowrap align-content-center w-100">
                    <div class="d-flex flex-row flex-wrap gap-1">
                        <ng-container
                                *ngIf="location?.supportsPickup || location?.supportsCurbside">
                            <!-- location info link -->
                            <a
                                    *ngIf="displayInfoButton"
                                    type="button"
                                    tabindex="0"
                                    class="de-primary-link p-0 text-capitalize text-nowrap pointer me-3"
                                    (click)="locationInfoButtonClicked($event)"
                                    (keydown.enter)="locationInfoButtonClicked($event)">
                                More Info
                            </a>
                        </ng-container>
                        <!-- catering button -->
                        <a
                                *ngIf="location?.cateringLink"
                                type="button"
                                tabindex="0"
                                class="de-primary-link p-0 text-capitalize text-nowrap pointer me-3"
                                [href]="location?.cateringLink"
                                (click)="cateringClicked($event)"
                                (keydown.enter)="cateringClicked($event)">
                            Catering
                        </a>
                        <ng-container *ngIf="displayOrderButton && location.isLive">
                            <ng-container *ngIf="!locationList">
                                <a
                                        type="button"
                                        tabindex="0"
                                        class="de-primary-link p-0 text-capitalize text-nowrap pointer me-3"
                                        (click)="openNavigation($event)"
                                        (keydown.enter)="openNavigation($event)">
                                    Get Directions
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                    <ng-container
                            class="w-100 d-flex flex-row flex-wrap"
                            *ngIf="displayOrderButton && location.isLive">
                        <!-- order now button -->
                        <button
                                type="button"
                                *ngIf="!editHandoff"
                                [attr.data-cy]="location.name"
                                class="select-location-arrow ms-auto p-0 pointer cursor-pointer text-capitalize"
                                [disabled]="!location?.supportsPickup && !location?.supportsCurbside && pageFrom !== 'details'">
                            <div *ngIf="locationLoading === location?.locationID"
                                 class="select-location-btn-loader"></div>
                            <div *ngIf="locationLoading !== location?.locationID && (location?.supportsPickup || location?.supportsCurbside ||location?.supportsDriveThru)"
                                 class="d-flex flex-row justify-content-end align-items-center fw-bold ms-3">
                                <span class="text-end text-nowrap">{{location?.isOpen ? (!locationList ? 'Order Now' : 'Order Pickup') : (!locationList ? 'Order Ahead' : 'Schedule Pickup') }}</span>
                                <i class="bi bi-arrow-right-circle font-size-subheading-1 d-flex ms-2"></i>
                            </div>
                            <div *ngIf="locationLoading !== location?.locationID && !(location?.supportsPickup || location?.supportsCurbside || location?.supportsDriveThru)"
                                 class="d-flex flex-row justify-content-end align-items-center ms-3">
                                <span class="text-end text-nowrap">Get Delivery</span>
                                <i class="bi bi-arrow-right-circle font-size-subheading-1 d-flex ms-2"></i>
                            </div>
                        </button>
                        <!-- edit order type button -->
                        <button
                                type="button"
                                *ngIf="editHandoff"
                                class="ms-auto select-location-arrow"
                                (click)="changeHandoffTypeClicked()"
                                [disabled]="!location?.supportsPickup && !location?.supportsCurbside && pageFrom !== 'details'">
                            <div *ngIf="locationLoading === location?.locationID" class="de-btn-loader"></div>
                            <div *ngIf="locationLoading !== location?.locationID">Select</div>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #skeleton>
  <div class="d-flex flex-row flex-wrap grow-1 w-100">

    <!-- Location Information (Left) -->
    <div class="d-flex flex-row flex-nowrap grow-1 pb-2 w-100">
      <div class="w-100 fw-bold">

        <!-- Location Name Skeleton -->
        <ion-skeleton-text animated style="width: 60%; height: 24px;" class="mb-2"></ion-skeleton-text>

        <!-- Address Line 1 Skeleton -->
        <ion-skeleton-text animated style="width: 70%; height: 16px;" class="mb-1"></ion-skeleton-text>

        <!-- Address Line 2 Skeleton -->
        <ion-skeleton-text animated style="width: 50%; height: 16px;" class="mb-2"></ion-skeleton-text>

        <!-- Status (Open/Closed) Skeleton -->
        <ion-skeleton-text animated style="width: 40px; height: 20px;" class="me-2"></ion-skeleton-text>

      </div>

      <!-- Distance Skeleton -->
      <div class="ps-2">
        <ion-skeleton-text animated style="width: 40px; height: 20px;"></ion-skeleton-text>
      </div>
    </div>

    <!-- Phone Number Skeleton -->
    <ion-skeleton-text animated style="width: 50%; height: 16px;" class="mb-2"></ion-skeleton-text>

    <!-- Hours Skeleton -->
    <ion-skeleton-text animated style="width: 60%; height: 16px;" class="mb-2"></ion-skeleton-text>

    <!-- Order Methods Skeleton -->
    <ion-skeleton-text animated style="width: 80%; height: 16px;" class="mb-2"></ion-skeleton-text>

    <!-- Action Buttons Skeleton -->
    <div class="d-flex justify-content-between w-100">
      <ion-skeleton-text animated style="width: 80px; height: 40px;" class="me-2"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 80px; height: 40px;"></ion-skeleton-text>
    </div>

  </div>
</ng-template>
