<app-section-frame [sectionTitle]="'Ready Time'">
  <div [ngClass]="order?.location?.isOpen ? 'w-100' : ''">
    <div
      ngbNav
      #nav="ngbNav"
      [activeId]="isAsap"
      class="d-flex flex-row flex-nowrap position-relative">
      <ng-container *ngIf="!order?.location?.isOpen">
        <div class="d-flex flex-column w-100 mb-4" id="location-closed">
          <ng-container
            *ngIf="
              !order.location.isOpen && order?.location?.supportsAdvancedOrders
            ">
            <h5 class="mb-2 fw-bold header-font text-capitalize">
              Restaurant is Currently Closed
            </h5>
            <div class="text-muted">
              You can still schedule your order ahead for when it is open, or
              try a different location.
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          order?.location?.supportsAsapOrders &&
          !order?.location?.supportsAdvancedOrders
        ">
        <ng-container *ngIf="order?.location?.isOpen">
          <div class="d-flex align-items-center w-100" style="height: 38px">
            Ready at:&nbsp;<b>{{
              order?.earliestReadyTimestamp
                | date: 'M/d/yy h:mm a' : order.location.utcOffset
            }}</b>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <ng-container
      *ngIf="
        order?.location?.supportsAsapOrders ||
        order?.location?.supportsAdvancedOrders
      ">
      <ng-container *ngIf="actualTimes; else loadingTimes">
        <div class="d-flex flex-column flex-grow-1 mb-4">
          <ng-container
            *ngIf="
              !order?.location?.isOpen &&
              order?.location?.supportsAdvancedOrders
            ">
            <h5 class="fw-bold header-font flex-grow-1 mb-2">
              Schedule Ahead
            </h5>
          </ng-container>

          <div
            class="d-flex flex-row flex-nowrap align-items-center justify-content-between mb-2">
            <div class="flex-grow-1 d-flex flex-column">
              <h6
                class="header-font fw-bold p-0 m-0 d-flex flex-row flex-nowrap">
                Select a Date
                <div
                  class="de-btn-mini-loader btn-loader m-0 ms-2"
                  *ngIf="dateLoading"></div>
              </h6>
              <!--                        <span *ngIf="order.orderReadyTimestamp && !order.isASAP" class="badge bg-success mt-1 w-fit-content">-->
              <!--                            Current: {{order.orderReadyTimestamp | date: 'shortDate' : order.location.utcOffset}}</span>-->
            </div>
            <button
              type="button"
              class="primary-link bg-transparent p-0 font-size-body-large"
              (click)="toggleDays()"
              *ngIf="availableDates?.length | timeSelectShowMoreCheck">
              <ng-container *ngIf="!showDays"
                ><i class="bi bi-plus-circle color-primary"></i>
              </ng-container>
              <ng-container *ngIf="showDays"
                ><i class="bi bi-dash-circle color-primary"></i>
              </ng-container>
            </button>
          </div>

          <form>
            <div
              class="select-day-grid"
              [@openClose]="showDays ? 'open' : 'closed'">
              <ng-container
                *ngFor="
                  let date of availableDates;
                  trackBy: availableDateTracker
                ">
                <button
                  [disabled]="dateLoading"
                  [class.disabled]="dateLoading"
                  type="button"
                  class="w-100 text-nowrap"
                  [class.de-primary-btn]="
                    date
                      | timeComparison
                        : (this.selectedDate
                            ? this.selectedDate
                            : order.isASAP
                            ? order.earliestReadyTimestamp
                            : order.orderReadyTimestamp)
                        : 'day'
                  "
                  [class.de-primary-btn-outline]="
                    !(
                      date
                      | timeComparison
                        : (this.selectedDate
                            ? this.selectedDate
                            : order.isASAP
                            ? order.earliestReadyTimestamp
                            : order.orderReadyTimestamp)
                        : 'day'
                    )
                  "
                  (click)="onDateChange(date)">
                  {{ date | date: 'shortDate' : order.location.utcOffset }}
                </button>
              </ng-container>
            </div>
          </form>
        </div>

        <div class="d-flex flex-column flex-grow-1">
          <div
            class="d-flex flex-row justify-content-between align-items-center mb-2">
            <div class="flex-grow-1 d-flex flex-column">
              <h6
                class="header-font fw-bold p-0 m-0 d-flex flex-row flex-nowrap">
                Select a Time
                <div
                  class="de-btn-mini-loader btn-loader m-0 ms-2"
                  *ngIf="timeLoading"></div>
              </h6>
            </div>
            <button
              type="button"
              class="primary-link bg-transparent p-0 font-size-body-large"
              (click)="toggleTimes()"
              *ngIf="actualTimes.length | timeSelectShowMoreCheck">
              <ng-container *ngIf="!showTimes"
                ><i class="bi bi-plus-circle color-primary"></i>
              </ng-container>
              <ng-container *ngIf="showTimes"
                ><i class="bi bi-dash-circle color-primary"></i>
              </ng-container>
            </button>
          </div>
          <form>
            <ng-container *ngIf="actualTimes.length > 0; else noTimes">
              <div
                class="select-time-grid"
                [@openClose]="showTimes ? 'open' : 'closed'">
                <ng-container
                  *ngIf="
                    order?.location?.supportsAsapOrders &&
                    order?.location?.isOpen
                  ">
                  <ng-container
                    *ngIf="
                      !selectedDate ||
                      (selectedDate
                        | timeComparison: order.earliestReadyTimestamp : 'day')
                    ">
                    <ng-container
                      *ngIf="
                        order.earliestReadyTimestamp
                          | timeComparison: today : 'day'
                      ">
                      <button
                        type="button"
                        class="w-100 text-nowrap d-flex align-items-center justify-content-center flex-column"
                        [disabled]="timeLoading"
                        (click)="asapClicked()"
                        [class.de-primary-btn-outline]="!order.isASAP"
                        [class.de-primary-btn]="order.isASAP"
                        [class.de-primary-btn-disabled]="
                          !order?.location?.isOpen
                        "
                        [ngClass]="!order?.location?.isOpen ? 'd-none' : ''">
                        <ng-container *ngIf="loadingTimeMode !== 'asap'"
                          >ASAP
                          <small
                            class="fw-bold"
                            style="font-size: 0.625rem"
                            >Today
                            {{
                              order.earliestReadyTimestamp
                                | date: 'shortTime' : order.location.utcOffset
                            }}</small
                          >
                        </ng-container>
                        <ng-container *ngIf="loadingTimeMode === 'asap'">
                          <div
                            class="de-btn-mini-loader btn-loader m-0"
                            style="
                              border-top: 2px solid var(--primary-text);
                              border-end: 2px solid var(--primary-text);
                            "></div>
                        </ng-container>
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="order?.location?.supportsAdvancedOrders">
                  <ng-container
                    *ngFor="
                      let time of actualTimes;
                      let i = index;
                      trackBy: availableTimeTracker
                    ">
                    <ng-container
                      *ngIf="
                        !(
                          time
                          | timeComparison
                            : order.earliestReadyTimestamp
                            : 'minute'
                        )
                      ">
                      <button
                        [disabled]="timeLoading"
                        type="button"
                        class="w-100 text-nowrap"
                        [class.disabled]="timeLoading"
                        [value]="time"
                        [class.de-primary-btn-outline]="
                          !(
                            time
                            | timeComparison
                              : order.orderReadyTimestamp
                              : 'minute'
                          )
                        "
                        [class.de-primary-btn]="
                          time
                            | timeComparison
                              : order.orderReadyTimestamp
                              : 'minute'
                        "
                        (click)="onTimeChange(time)">
                        {{
                          time | timezoneConvert : 'h:mm A' : order.location.timezone
                        }}
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #noTimes>
              <div
                id="no-times" [attr.data-open]="order.location.isOpen"
                class="alert alert-warning w-100 text-center de-card-radius px-3 py-2 m-0">
                There are no more times available for this date, please select
                another day.
              </div>
            </ng-template>
          </form>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loadingTimes>
      <div
        class="card d-flex align-items-center justify-content-center flex-column w-100 text-center de-card-radius p-4 m-0">
        <div class="item mb-3">
          <div class="item-inner">
            <div class="item-loader-container">
              <div class="la-ball-pulse-sync">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div>Getting Available Dates and Times</div>
      </div>
    </ng-template>
  </div>
  <div class="mt-2 text-muted font-size-body-small">
    Times are shown in restaurant's time zone.
  </div>

</app-section-frame>
