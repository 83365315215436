import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Menu } from '../../../interfaces/menu.interface';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { MobileService } from '../../../services/mobile.service';
import { Router } from '@angular/router';
import { ThemeColor } from '../../../vendors/directus/interfaces/theme-color.interface';
import { Order } from '../../../interfaces/order.interface';
import { MainSettings } from '../../../vendors/directus/interfaces/main-settings.interface';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { NgElement, WithProperties } from '@angular/elements';
import { HomeMenuComponent } from '../components/home-menu/home-menu.component';
import { Category } from '../../../vendors/directus/interfaces/category.interface';
import { Category as DineEngineCategory } from '../../../interfaces/category.interface';
import { HomeReorderComponent } from '../components/home-reorder/home-reorder.component';
import Instafeed from 'instafeed.js';
import { InstagramResponse } from '../models/instagram.interface';
import { InstafeedComponent } from '../components/instafeed/instafeed.component';
import { FeaturedItemsComponent } from '../components/featured-items/featured-items.component';
import { Product } from '../../../vendors/directus/interfaces/product.interface';
import { Branding } from '../../../vendors/directus/interfaces/branding.interface';
import { NavigationService } from '@modules/navigation/services';
import { StaticLocationsComponent } from '@modules/brochure/containers/static-locations/static-locations.component';
import { Location as DineEngineLocation } from '../../../interfaces/location.interface';

@Injectable({
  providedIn: 'root',
})
export class DynamicElementsService {
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.location.staticLocations) staticLocations$: Observable<DineEngineLocation[]>;

  subs: Subscription[] = [];

  constructor(
    private directus: DirectusService,
    private mobile: MobileService,
    private router: Router,
    private navigation: NavigationService
  ) {}

  startMonitoring() {
    // Menu

    this.subs.push(
      this.order$
        .pipe(distinctUntilChanged((prev, curr) => prev === curr || (prev && curr && prev.orderID === curr.orderID)))
        .subscribe(() => {
          const menuElement: NgElement & WithProperties<HomeMenuComponent> = document.createElement('dineengine-menu') as any;
          menuElement.addEventListener('categoryClick', (category: CustomEvent<DineEngineCategory>) => {
            this.subs.push(
              this.order$.pipe(take(1)).subscribe(order => {
                if (order) {
                  const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
                  const categoryID = category.detail.nameSlug ? category.detail.nameSlug : category.detail.categoryID;
                  this.navigation.navigateToMenuPage(menuID, categoryID);
                } else {
                  this.navigation.navigateToLocationsPage();
                }
              })
            );
          });
          this.subs.push(
            combineLatest(
              this.directus.getContentCategories(),
              this.menu$,
              this.theme$.pipe(filter(t => t !== null)),
              this.mainSettings$
            ).subscribe(([dMenu, sMenu, theme, mSettings]) => {
              if (sMenu) {
                menuElement.displayMenu = sMenu;
              } else {
                menuElement.displayMenu = {
                  menuID: null,
                  categories: dMenu
                    .filter(c => c.show_on_homepage && c.home_page_menu_sort)
                    .sort((a, b) =>
                      a.home_page_menu_sort < b.home_page_menu_sort ? -1 : a.home_page_menu_sort === b.home_page_menu_sort ? 0 : 1
                    )
                    .map(c => this.contentCategoryToCategory(c)),
                  singleUseProducts: null,
                };
              }
              menuElement.displayTheme = theme[0];
              menuElement.isMobile = this.mobile.isMobile;
              menuElement.mainSettings = mSettings;
              const builderElement = document.querySelector('app-home-menu');
              if (builderElement) {
                builderElement.parentNode.replaceChild(menuElement, builderElement);
              } else {
                const oldMenuEl = document.querySelector('dineengine-menu');
                if (oldMenuEl) {
                  oldMenuEl.parentNode.replaceChild(menuElement, oldMenuEl);
                }
              }
            })
          );
        })
    );

    // Orders

    this.subs.push(
      this.pastOrders$
        .pipe(
          filter(o => o !== null),
          // tslint:disable-next-line:max-line-length
          distinctUntilChanged((prev, next) => prev && prev.length === next.length && prev[0]?.orderStatus === next[0]?.orderStatus)
        )
        .subscribe(pOrders => {
          const ordersElement: NgElement & WithProperties<HomeReorderComponent> = document.createElement('dineengine-orders') as any;
          ordersElement.displayPreviousOrders = pOrders;
          ordersElement.isMobile = this.mobile.isMobile;
          const builderElement = document.querySelector('app-home-reorder');
          if (builderElement) {
            builderElement.parentNode.replaceChild(ordersElement, builderElement);
          } else {
            const oldOrdersEl = document.querySelector('dineengine-orders');
            if (oldOrdersEl) {
              oldOrdersEl.parentNode.replaceChild(ordersElement, oldOrdersEl);
            }
          }
        })
    );

    // Instagram

    // tslint:disable-next-line:max-line-length
    this.subs.push(
      combineLatest(this.mainSettings$.pipe(filter(ms => ms !== null)), this.branding$.pipe(filter(b => b !== null))).subscribe(
        ([ms, branding]) => {
          if (ms.instagram_refresh_token && document.getElementById('instafeed')) {
            const userFeed = new Instafeed({
              get: 'user',
              accessToken: ms.instagram_refresh_token,
              limit: 12,
              sortBy: 'least-recent',
              template: '',
              orientation: 'square',
              success: (response: InstagramResponse) => {
                const instaElement: NgElement & WithProperties<InstafeedComponent> = document.createElement('dineengine-instafeed') as any;
                instaElement.images = response.data;
                instaElement.mainSettings = ms;
                instaElement.isMobile = this.mobile.isMobile;
                if (branding.instagram_profile) {
                  instaElement.profileImage = branding.instagram_profile.data.full_url;
                }
                const builderElement = document.querySelector('app-instafeed');
                if (builderElement) {
                  builderElement.parentNode.replaceChild(instaElement, builderElement);
                } else {
                  const oldInstaEl = document.querySelector('dineengine-instafeed');
                  if (oldInstaEl) {
                    oldInstaEl.parentNode.replaceChild(instaElement, oldInstaEl);
                  }
                }
              },
              error: error => {
                console.log(error);
              },
            });
            userFeed.run();
          }
        }
      )
    );

    // Featured Items

    this.directus.getContentProducts().subscribe(dProducts => {
      const featuredItemsElement: NgElement & WithProperties<FeaturedItemsComponent> = document.createElement(
        'dineengine-featured-items'
      ) as any;
      featuredItemsElement.addEventListener('productSelected', (product: CustomEvent<Product>) => {
        this.subs.push(
          this.order$.pipe(take(1)).subscribe(order => {
            if (order) {
              const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
              this.navigation.navigateToMenuPage(menuID, product.detail.category_slug, product.detail.name_slug, 'modify');
            } else {
              this.navigation.navigateToLocationsPage();
            }
          })
        );
      });
      // tslint:disable-next-line:max-line-length
      featuredItemsElement.products = dProducts
        .filter(p => p.is_featured_item)
        .sort((a, b) => a.featured_item_sort_order - b.featured_item_sort_order);
      featuredItemsElement.isMobile = this.mobile.isMobile;
      const builderElement = document.querySelector('app-featured-items');
      if (builderElement) {
        builderElement.parentNode.replaceChild(featuredItemsElement, builderElement);
      } else {
        const oldFeaturedItems = document.querySelector('dineengine-featured-items');
        if (oldFeaturedItems) {
          oldFeaturedItems.parentNode.replaceChild(featuredItemsElement, oldFeaturedItems);
        }
      }
    });

    // Static Locations

    this.subs.push(
      this.staticLocations$.subscribe(pOrders => {
        // tslint:disable-next-line:max-line-length
        const ordersElement: NgElement & WithProperties<StaticLocationsComponent> = document.createElement(
          'dineengine-static-locations'
        ) as any;
        const builderElement = document.querySelector('app-static-locations');
        if (builderElement) {
          builderElement.parentNode.replaceChild(ordersElement, builderElement);
        } else {
          const oldLocationsEl = document.querySelector('dineengine-static-locations');
          if (oldLocationsEl) {
            oldLocationsEl.parentNode.replaceChild(ordersElement, oldLocationsEl);
          }
        }
      })
    );
  }

  stopMonitoring() {
    this.subs.forEach(s => s.unsubscribe());
  }

  private contentCategoryToCategory(category: Category): DineEngineCategory {
    return {
      categoryID: String(category.id),
      description: category.description,
      name: category.name,
      isHidden: category.hide_category,
      nameSlug: category.name_slug,
      products: [],
      seoDescription: category.seo_description,
      standardImageURL: category.image?.data.full_url,
      thumbnailImageURL: category.image?.data.thumbnails.find(t => t.width === 600).url,
    } as DineEngineCategory;
  }
}
